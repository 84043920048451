/* -------------------
 * Colors
\* ------------------- */

// Neutrals
$white: #ffffff;
$off-white: #f7f7f7;
$grey-lightest: #e2e3e4;
$grey-lighter: #e6e6e6;
$grey-light: #cccccc;
$grey: #666666;
$grey-dark: #6d6e71;
$grey-darker: darken($grey-dark, 10%);
$black: #000000;
// Colors
$yellow: #ffde00;
$red: #d6262c;
// Blues
$blue-lighter: #90c4f3;
$blue-light: #3096f0;
$blue: #0f73d0;
$blue-dark: #01529a;
$blue-darker: #022646;
$purple: #310099;

// Other
$border-color: $grey-light;
$font-color: $grey;
$blue-background-color: $blue-dark;
$shadow-color: darken($border-color, 15%);
$danger-red: #fcd4d4;

/* -------------------
 * Fonts
\* ------------------- */
$font-family-primary: "AvenirLTStd35", sans-serif;
$font-family-secondary: "AvenirNextCondensed", sans-serif;
$font-family-base: "PT Sans", sans-serif;
