.site-header {
    z-index: 999;

    &__top {
        box-shadow: 0px -10px 12px 8px $shadow-color;
    }

    &__content {
        align-items: center;
        display: flex;
        height: 36px;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 1000px;
        padding: 15px;
    }

    &__logo {
        margin: 0;
        min-width: 125px;
    }

    &__mobile-menu {
        a {
            cursor: pointer;
            display: block;
            padding: 10px;

            img {
                width: 25px;
            }
        }
    }
}