.brightcove-player {
	position: relative;
	width: 100%;

	&:before {
		content: '';
		display: block;
		// 16:9 Ratio
		padding-top: 56.25%;
	}

	&__video {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}