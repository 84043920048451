.brightcove-video-search {
    width: 100%;

    &__loader {
        display: inline-block;
        height: 39px;
        margin-left: 10px;
        vertical-align: middle;
    }

    &__forget-videos {
        color: $blue;
        cursor: pointer;
        display: inline-block;
        font-size: 20px;
        margin-left: 10px;
        vertical-align: middle;
    }

    &__video-name {
        margin-bottom: 10px;
        padding: 10px 0;
    }

    &__bc-video-list {
        border: 1px solid $grey-light;
        display: flex;
        flex-direction: column;
        height: 550px;
        overflow: auto;
        padding: 5px;
    }

    &__video-error {
        color: $red;
    }

    &__bc-video-item {
        border: 1px solid $grey-light;
        margin-bottom: 5px;

        &:hover {
            background-color: #f0f0f0 !important;
            cursor: pointer;
        }

        &--alt {
            background-color: #e0e0e0;

            &:hover {
                background-color: #d0d0d0 !important;
            }
        }

        &--selected {
            background-color: $blue-lighter !important;

            &:hover {
                background-color: darken($blue-lighter, 10%) !important;
            }
        }

        img {
            float: left;
            margin-right: 15px;
        }

        &-thumbnail {
            width: 150px;
        }
    }

    &__tags {
        margin-bottom: 30px;
    }
}