/* -------------------
 * Font Styles
\* ------------------- */

/*
Montserrat
*/

$font-styles: (
    "Thin" : 100,
    "ExtraLight" : 200,
    "Light" : 300,
    "Italic" : 400,
    "Regular" : 400,
    "Medium" : 500,
    "SemiBold" : 600,
    "Bold" : 700,
    "ExtraBold" : 800,
    "Black" : 900
);

@mixin font-montserrat($variation: "Regular") {
    font: {
        family: "Montserrat", Helvetica, Arial, sans-serif;
        style: if($variation == "Italic", italic, normal);
        weight: map-get($font-styles, $variation);
    }
}

@font-face {
    font-family: "Montserrat";
    src: url("/fonts/Montserrat-VariableFont_wght.woff2") format("woff2"),
    url("/fonts/Montserrat-VariableFont_wght.woff") format("woff");
    font-weight: auto;
    font-style: normal;
}

/*
Social Media Icons
*/
@font-face {
    font-family: "social_shapesregular";
    src: url("/fonts/socialshapes-webfont.woff2") format("woff2"),
    url("/fonts/socialshapes-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}