.brochure-banner {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
    width: 100%;

    @include media-query(768px) {
        column-gap: 15px;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    &__image {
        align-self: center;
        margin-bottom: 15px;
        max-width: 425px;
        width: 90%;
    }

    &__body {
        display: flex;
        flex-direction: column;

        &-title {
            font-size: em(20px);
            line-height: em(28px, 20px);
            max-width: 475px;
            text-transform: uppercase;
            width: 100%;
        }

        &-text {
            line-height: em(30px);
            margin-bottom: 25px;
            max-width: 535px;
        }

        @at-root a#{&}-button {
            @include font-montserrat("Bold");
            border: 2px solid black;
            color: black;
            font-size: em(20px);
            max-width: 475px;
            padding: 15px 0;
            text-align: center;
            text-transform: uppercase;
            width: 100%;

            @include media-query(768px) {
                max-width: 285px;
            }
        }
    }
}