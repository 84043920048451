body {
    @include font-montserrat();
    color: $font-color;
}

a, a:link, a:visited, a:active {
    color: $blue;
    outline: none;
    text-decoration: none;
}

a:hover {
    color: darken($blue, 10%);
}

hr {
    border: 0;
    border-top: 1px solid $grey-lighter;
    display: block;
    height: 1px;
    margin: 1em 0;
    padding: 0;
    width: 100%;
}

.content {
    margin: 0 auto;
    max-width: 875px;
    padding: 0;

    &--home, &--episode {
        color: black;
        max-width: none;
    }

    &--padded {
        padding: 25px 32px 50px;

        @include laptop {
            padding-bottom: 75px;
        }
    }
}

// Hidden Adara pixel
.hiddenpixel {
    display: none;
}

.alert-danger {
    background-color: $danger-red;
    border-radius: 5px;
    color: $red;
    margin-bottom: em(16);
    padding: em(16);
}