.breadcrumb {
    border-bottom: 1px solid $grey-lighter;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px 0;

    .breadcrumb__nav {
        display: flex;

        span {
            margin-left:5px;
        }

        &-item {
            .active {
                color: #868e96;
            }
        }
    }
}
