.listing-search {
    display: flex;
    flex-direction: column;

    &__container {
        display: none;
        overflow-y: auto;
        padding: 10px 10px 10px 10px;
        text-align: left;
    }

    &__columns {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include tablet {
            flex-direction: row;
        }
    }

    &__input {
        max-width: 100%;
        width: 100%;
    }

    &__button {
        max-width: 100px;
    }

    &__search-form {
        display: flex;
        flex-direction: row;
        gap: 30px;
    }
}