.header-bar {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: em(18px);
    padding-top: 45px;

    @include tablet {
        padding-top: 90px;
    }

    &__image {
        margin-bottom: 30px;
        max-width: 597px;
        width: 90%;

        @include tablet {
            margin-bottom: 60px;
        }
    }

    &__about {
        margin: 0 auto 15px;
        padding: 0 40px;
        text-align: center;

        @include tablet {
            margin-bottom: 60px;
            max-width: 670px;
        }
    }
}

.content-body {
    &__routes {
        align-items: center;
        background: var(--routes-background) no-repeat center, rgba(0, 0, 0, 0.6);
        background-blend-mode: overlay;
        background-size: cover;
        color: white;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        overflow: hidden;
        position: relative;

        @include media-query(768px) {
            margin-bottom: 60px;
        }

        &-content {
            margin: 0 auto;
            max-width: 1024px;
            padding: 0 15px;
        }

        &-title {
            margin-bottom: 10px;
            padding: 60px 5px 0;

            @include tablet {
                margin-bottom: 40px;
            }

            @include media-query(768px) {
                padding-top: 120px;
            }

            img {
                min-width: 293px;

                @include tablet {
                    max-width: 615px;
                    min-width: 500px;
                    width: 60vw;
                }
            }
        }

        &-tag {
            @include font-montserrat("Bold");
            font-size: em(13px);
            line-height: em(15px, 13px);
            margin-bottom: 30px;
            text-align: center;
            text-transform: uppercase;

            @include tablet {
                font-size: em(22px);
                line-height: em(26px, 22px);
                margin-bottom: 60px;
            }
        }

        &-description {
            line-height: em(26px, 16px);
            margin-bottom: 85px;
            max-width: 670px;
            padding: 0 5px;
            text-align: center;

            @include tablet {
                font-size: em(18px);
                line-height: em(30px, 18px);
                margin-bottom: 60px;
            }
        }

        &-video {
            margin-bottom: 45px;
            max-width: 1024px;
            width: 100%;

            @include tablet {
                margin-bottom: 90px;
            }

            &-title {
                @include font-montserrat("Bold");
                font-size: em(23px);
                line-height: em(28px, 23px);
                margin-bottom: 25px;
                max-width: 650px;
                text-align: center;
                text-transform: uppercase;

                @include tablet {
                    font-size: em(30px);
                    line-height: em(35px, 30px);
                    margin-bottom: 50px;
                }
            }

            &-description {
                font-size: em(16px);
                line-height: em(26px, 16px);
                max-width: 1024px;
                text-align: center;

                @include tablet {
                    font-size: em(18px);
                    line-height: em(30px, 18px);
                }
            }
        }

        &-slider {
            margin: 0 auto 45px;
            max-width: 90%;
        }

        &-separator {
            @include laptop {
                border-bottom: 1px solid #cccccc;
                margin: 20px auto;
                width: 720px;
            }
        }

        &-overlay {
            display: none;
            pointer-events: none;
            z-index: 1;

            @include media-query(768px) {
                background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 8%, rgba(0, 0, 0, 0) 92%, rgba(0, 0, 0, 1) 100%);
                bottom: 0;
                display: inline;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }

    &__additional-nominees {
        color: black;
        margin: 0 auto;
        max-width: 1024px;
        text-align: center;
        width: 90%;

        h3, h4 {
            text-transform: uppercase;
        }

        h3 {
            font-size: 24px;
            margin-bottom: 5px;
        }

        h4 {
            font-size: 18px;
        }

        h5 {
            font-size: 15px;
            font-weight: 400;
        }

        &-body {
            margin-bottom: 65px;

            &-group {
                column-gap: 5%;
                display: flex;
                flex-flow: row wrap;
                row-gap: 25px;

                .nominee {
                    flex-basis: 100%;

                    @include tablet {
                        flex-basis: 45%;
                    }

                    @include laptop {
                        flex-basis: 30%;
                    }
                }
            }
        }
    }
}

.advertising {
    padding: 25px 0;

    @include media-query(768px) {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        width: 720px;
    }

    &__ad-container-left, &__ad-container-right {
        margin-bottom: 20px;

        @include media-query(768px) {
            margin: 0;
        }
    }

    img {
        margin: 0 auto;
    }

    &__separator {
        display: none;

        @include media-query(768px) {
            border: 1px solid $border-color;
            display: block;
            height: 210px;
            margin: 25px 59px;
        }
    }
}