body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

footer {
    margin-top: auto;
}

.admin {
    &__search {
        align-items: center;
        column-gap: 15px;
        display: flex;
        margin-bottom: 30px;

        &-input {
            height: em(40px, 18px);
        }
    }

    &__button {
        border: 0;
        color: $white;
        cursor: pointer;
        display: inline-block;
        font-family: inherit;
        padding: 7px 10px;
        text-align: center;
        text-transform: uppercase;

        &:link, &:visited, &:active {
            color: white;
        }

        &--small {
            box-sizing: border-box;
            display: block;
            font-size: em(12px);
            line-height: em(12px);
            margin: 15px 0;
            padding: 5px;
            width: 100%;
        }

        &--blue {
            background-color: $purple;

            &:hover {
                background-color: darken($purple, 10%);
            }
        }

        &--yellow {
            background-color: $yellow;

            &:link, &:visited, &:hover {
                color: black;
            }

            &:hover {
                background-color: darken($yellow, 10%);
            }
        }

        &--green {
            background-color: green;

            &:hover {
                background-color: darken(green, 10%);
            }
        }

        &--danger {
            background-color: $red;

            &:hover {
                background-color: darken($red, 10%);
            }
        }

        &--mobile {
            @include tablet {
                display: none;
            }
        }

        &--desktop {
            display: none;

            @include tablet {
                display: inline-block;
            }
        }

        &--right {
            align-self: flex-end;
        }
    }

    &__controls {
        margin-bottom: 30px;

        @include tablet {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

.mobile-hidden {
    display: none;

    @include tablet {
        display: inline-block;
    }
}

.air-date {
    display: none;

    @include tablet {
        display: table-cell;
        min-width: 100px;
        text-align: center;
    }
}