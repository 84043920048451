.newsletter {
    .header-bar {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0;
        padding-top: 50px;

        @include tablet {
            padding-top: 100px;
        }

        &__image {
            max-width: 515px;
            width: 90%;

            @include tablet {
                margin-bottom: 30px;
            }
        }

        p {
            @include font-montserrat("Thin");
            font-size: clamp(24px, 7.5vw, 72px);
            margin: 0;
        }
    }

    .content {
        h2 {
            font-size: em(30px);
        }

        .center {
            text-align: center;
        }

        @include laptop {
            .form {
                align-items: center;
                display: flex;
                flex-direction: row;
                gap: 30px;
                justify-content: center;
                padding: 30px 0 40px;

                &__input, &__select, &__textarea {
                    max-width: none;
                }
            }
        }
    }
}
