/* -------------------
 * Thumbnails
\* ------------------- */

.thumbnails {
    margin: 0 0 40px;
    lost-utility: clearfix;

    &__thumbnail-content {
        lost-column: 1;
        position: relative;
        margin-bottom: 8px;

        &--last-odd{
            display: block;
        }
    }

    &__img {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
    }

    &__playbar {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 35px;
        background-color: rgba(23, 115, 198, 0.7);
        background: rgba(23, 115, 198, 0.7);
        color: rgba(23, 115, 198, 0.7);
    }

    &__story-title {
        float: left;
        margin: 8px 0 0 -2px;
        padding: 2px 4px;
        border-left: 4px solid $blue-lighter;
        background-color: $white;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        font-family: $font-family-base;
        letter-spacing: 0.03em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
        box-sizing: border-box;
    }

    &__play-button {
        position: absolute;
        width: 24px;
        top: 5px;
        right: 5px;
    }

    &__navigation {
        margin-top: 10px;
    }

    &__navigation-link {
        lost-column: 1/3;
        text-align: center;
        font-size: em(15px);
        font-family: $font-family-primary;
        white-space: nowrap;

        &:first-child {
            text-align: left;
        }

        &:last-child {
            text-align: right;
        }
    }
}

@include for-tablet-portrait-up {
    .thumbnails {
        &__thumbnail-content {
            lost-column: 1/2;

            &--last-odd{
                display: none;
            }
        }

        &__navigation-link {
            font-size: em(20px);
        }
    }
}

@include for-tablet-landscape-up {
    .thumbnails {
        &__thumbnail-content {
            lost-column: 1/3;

            &--last-odd{
                display: block;
            }
        }
    }
}

@include for-desktop-up {
    .thumbnails {
        &__navigation {
            margin-top: 50px;
        }
    }
}
