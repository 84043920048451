.nav {
    left: 0;
    position: absolute;
    top: 67px;
    width: 100%;

    input[type=checkbox] {
        display: none;
    }

    input:checked ~ .nav__list {
        left: 0;
    }

    &__list {
        background-color: rgba(255, 255, 255, .95);
        left: -100%;
        position: absolute;
        transition-duration: .3s;
        transition-property: left;
        transition-timing-function: ease-in-out;
        width: 100%;
        z-index: 105;

        @include laptop {
            background-color: transparent;
        }

        &-item {
            border-bottom: 1px solid #abadb0;
            display: block;
            margin: 0 auto;
            max-width: 1024px;
            text-align: center;
            width: 90%;

            @include laptop {
                background-color: rgba(255, 255, 255, .95);
            }
        }
    }

    &__link {
        @include font-montserrat();
        display: block;
        font-size: em(18px);
        line-height: em(18px, 18px);
        padding: 25px 0;
        text-decoration: none;

        &:link, &:active, &:visited, &:hover {
            color: #231f20;
        }

        &:hover {
            background-color: $grey-lightest;
        }
    }
}