.dashboard {
    &__item {
        border-bottom: 1px solid $grey-lighter;
        margin-bottom: 15px;
        padding-bottom: 15px;
    }

    &__item-description {
        display: block;
    }

    &__heading {
        font-family: "AvenirLTStd35", sans-serif;
        font-size: em(25px);
        font-weight: bold;
        margin-bottom: 10px;
        padding-top: 10px;
        text-transform: uppercase;
    }
}