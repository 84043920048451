.auth {
    display: flex;
    flex-direction: column;

    .columns {
        justify-content: space-between;

        .form__group-half {
            flex-basis: auto;
        }
    }
}

.form {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    padding: 30px 0;

    &__group {
        width: 100%;

        &-half {
            flex-basis: 100%;
            margin-bottom: 15px;

            @include tablet {
                max-width: 45%;
            }

            &--stacked {
                align-items: center;
                display: flex;
                flex-direction: column;
                margin-bottom: 15px;
                width: 100%;

                &:last-child {
                    margin-bottom: 0;
                }

                .image-label, img {
                    max-width: 500px;
                    width: 100%;
                }
            }
        }
    }

    &__label {
        display: inline-block;
        margin-bottom: 5px;
    }

    &__input, &__select, &__textarea {
        background: $grey-lightest none;
        border: 1px solid $grey-lightest;
        border-radius: 14px;
        box-sizing: border-box;
        display: block;
        font-family: inherit;
        font-size: em(18px);
        height: em(50px, 18px);
        line-height: em(18px, 18px);
        padding: em(15px, 18px);
        width: 100%;

        &::placeholder {
            color: $grey;
        }
    }

    &__textarea {
        height: auto;
        max-height: 400px;
        resize: vertical;
    }

    &__select-multiple {
        height: 150px;
        width: 100%;
    }

    select {
        appearance: none;
        border: 1px solid $grey-lightest;
        border-radius: 14px;
        font-size: 1em;
        padding: em(10px) 0 em(10px) em(10px);
    }

    &__required {
        color: $red;
    }

    &__button {
        background-color: $purple;
        border: 0;
        border-radius: 10px;
        color: $white;
        cursor: pointer;
        font-family: inherit;
        font-size: em(18px);
        max-width: 300px;
        padding: em(10px, 18px);
        text-transform: uppercase;

        &:hover {
            background-color: darken($purple, 10%);
        }

        &--green {
            background-color: green;

            &:hover {
                background-color: darken(green, 10%);
            }
        }

        &--newsletter {
            width: 80%;
        }
    }

    &__image-checkbox {
        display: inline-block;
        margin-top: 10px;
    }

    &__file {
        margin-bottom: 10px;
        width: 100%;
    }

    .columns {
        display: flex;
        flex-direction: column;
        gap: 10%;

        @include tablet {
            flex-direction: row;
        }
    }
}

.special_input {
    height: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 0;
    z-index: -1;
}

.form__admin {
    .form {
        &__input, &__select, &__textarea {
            background: white;
        }
    }

    h3 {
        text-align: center;
    }
}