/*-------------------
 * Global Resets
\* ------------------ */

* {
    outline: none;
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: none;
}

* html div {
    overflow: hidden;
}

p, ol, ul, h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

ul, li {
    list-style-type: none;
}

a img {
    border: none;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

object, iframe {
    max-width: 100%;
}