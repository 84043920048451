table {
    width: 100%;
}

table, th, td {
    border: 1px solid $grey;
    border-collapse: collapse;
}

th, td {
    padding: 10px 5px;
}

table tr:nth-child(even) {
    background-color: $grey-lighter;
}

table tr:nth-child(odd) {
    background-color: $off-white;
}

table th {
    background-color: $grey;
    color: $white;
}

.action-column {
    width: 10%;
}