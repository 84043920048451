/* -------------------
 * Slideshow
\* ------------------- */
$slideshow-max-width: 1200px;

.twenty-nine {
    lost-utility: clearfix;
    position: relative;
    margin: 0 0 30px;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (calc(9 / 16)) * 100%;
    }

    > .cycle-slideshow {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.slideshow {

    &__container {
        display: none;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-width: $slideshow-max-width;

        &:first-child {
            display: block;
            visibility: visible;
        }
    }

    &__content {
        width: 100%;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        text-align: center;
        color: $white;
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        background: url(../images/slide-blue-bar.png) no-repeat center;

        h2 {
            font-size: 25px;
        }
    }

    &__episode-play-button {
        display: none;

        img {
            display: inline-block;
            margin: 10px 0 0;
        }
    }

    &__image {
        overflow: hidden;
        height: 100%;

        img {
            height: 100%;
            margin-left: 50%;
            transform: translateX(-50%);
            max-width: 100%
        }
    }
}

.prev, .next {
    display: none;
}

@include for-tablet-portrait-up {
    .slideshow {
        &__episode-play-button {
            display: block;
        }
    }
}

@include for-tablet-landscape-up {

    .twenty-nine {
        &:before {
            padding-top: (calc(9 / 20)) * 100%;
        }
    }

    .slideshow {

        &__container {
            overflow: hidden;
        }

        &__image {
            position: absolute;
            height: auto;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .prev {
        left: 10px;
        background: url(../images/left-arrow.png) no-repeat;
    }

    .next {
        right: 10px;
        background: url(../images/right-arrow.png) no-repeat;
    }

    .prev, .next {
        display: block;
        position: absolute;
        z-index: 999;
        top: 50%;
        transform: translateY(-50%);
        width: 35px;
        height: 61px;
        cursor: pointer;
        text-indent: -9999px;
    }

    .prev:hover, .next:hover {
        opacity: .7;
        filter: alpha(opacity=70)
    }
}

@include for-desktop-up {
    .prev {
        left: -70px;
    }

    .next {
        right: -70px;
    }
}