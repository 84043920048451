/* -------------------
 * Typography
\* ------------------- */

h1 {
    margin: 0 0 10px;
    font-size: em(25px);
    font-family: $font-family-primary;
    text-transform: uppercase;
}

h2 {
    margin: 0 0 5px;
    font-size: em(18px);
    font-family: $font-family-primary;
    text-transform: uppercase;
}

p, ul, ol {
    margin-bottom: 30px;
    line-height: 1.5em;
}

ul {
    li {
        margin-left: 20px;
        list-style-type: disc;
    }
}

ol {
    li {
        margin-left: 30px;
        list-style-type: decimal;
    }
}