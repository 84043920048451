.multi-select {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 15px;
    row-gap: 15px;
    width: 100%;

    @include tablet {
        column-gap: 15px;
        flex-direction: row;
        row-gap: 0;
    }

    &__container {
        flex-basis: 35%;

        &-buttons {
            display: flex;
            flex-basis: 20%;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto;
            width: 100%;
        }
    }

    &__buttons {
        align-items: center;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }

    .form__button {
        width: 100%;
    }

    &__sort {
        display: flex;
        flex-basis: 100%;
        gap: 15px;
        justify-content: flex-end;

        @include tablet {
            margin-top: 15px;

            .form__button {
                flex-basis: 16%;
            }
        }
    }
}