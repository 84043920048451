.leaflet-map__container {
    width: 100%;

    &-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: 0 auto 35px;
        max-width: 920px;
        width: 100%;

        &-title {
            font-size: 24px;
            margin-bottom: 25px;
            text-align: center;
            text-transform: uppercase;
        }

        &-map {
            height: 330px;
            overflow: hidden;
            width: 100%;

            @include tablet {
                height: 530px;
            }

            @include laptop {
                height: 630px;
            }

            .marker-pin {
                background-color: black;
                border-radius: 50% 50% 50% 0;
                height: 50px;
                left: 50%;
                margin: -15px 0 0 -15px;
                position: absolute;
                top: 50%;
                transform: rotate(-45deg);
                width: 50px;

                &-background {
                    background-color: white;
                    border-radius: 50% 50% 50% 50%;
                    height: 40px;
                    margin: 5px auto;
                    transform: rotate(45deg);
                    width: 40px;

                    img.marker-pin-image {
                        padding: 5px;
                        width: 30px;
                    }
                }
            }
        }
    }
}