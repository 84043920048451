.site-footer {
    @include font-montserrat("Light");
    background-color: #231f20;

    &__container {
        align-items: center;
        color: $white;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 640px;
        padding: 35px 10px;
    }

    &__links {
        display: flex;
        flex-wrap: wrap;
        font-size: em(14px);
        gap: 25px 0;
        justify-content: space-evenly;
        margin-bottom: 30px;
        width: 100%;

        a {
            color: $white;
            flex-basis: 20%;
            min-width: 100px;
            text-align: center;
        }
    }

    &__logos {
        align-items: center;
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 30px;
        width: 100%;

        &-travelok {
            max-width: 110px;

            @include tablet {
                max-width: 200px;
            }
        }

        &-state-park {
            max-width: 50px;

            @include tablet {
                max-width: 110px;
            }
        }

        &-oklahoma-today {
            max-width: 115px;

            @include tablet {
                max-width: 210px;
            }
        }

        a {
            flex-basis: 33%;

            img {
                width: 100%;
            }
        }
    }

    &__bottom {
        align-items: center;
        display: flex;
        flex-direction: column-reverse;
        gap: 25px 0;
        justify-content: space-between;
        width: 100%;

        @include tablet {
            flex-direction: row;
        }
    }

    &__copyright {
        font-size: em(11px);

        a {
            color: $white;
            text-decoration: underline;
        }
    }

    &__social {
        display: flex;
        gap: 10px;

        a {
            img {
                height: 32px;
                width: 32px;
            }
        }
    }
}
