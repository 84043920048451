.swiper {
    margin-bottom: 50px;
    width: 100%;
    z-index: auto;

    @include media-query(768px) {
        left: -12%;
        margin-bottom: 110px;
        width: 124%;
    }

    &-slide {
        display: flex;
        height: auto;

        &__body {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 40px;
            text-align: center;
            width: 100%;

            @include media-query(768px) {
                padding: 0;
            }

            &-title {
                font-size: 18px;
                margin-bottom: 25px;
                text-transform: uppercase;

                @include media-query(768px) {
                    font-size: 25px;
                }
            }

            &-description {
                margin-bottom: 35px;

                p {
                    margin: 0;
                }
            }

            a {
                overflow: hidden;
                position: relative;
                width: 100%;
            }

            &-image {
                width: 100%;
            }

            &-number {
                @include font-montserrat("Black");
                bottom: -0.15em;
                color: white;
                font-size: clamp(50px, 15.5vw, 100px);
                left: 20px;
                line-height: 1em;
                paint-order: stroke fill;
                position: absolute;
                -webkit-text-stroke: 3px black;

                @include media-query(768px) {
                    bottom: -2px;
                    font-size: 105px;
                    line-height: 80px;
                }
            }
        }
    }

    &-navigation {
        // Calculate thumbnail's aspect ratio to screen width and divide by 2 to center navigation on thumbnail
        bottom: calc(((100vw - 80px) * 0.56333) / 2);
        position: absolute;
        width: 100%;

        @include media-query(768px) {
            // Calculate single slide width compared to thumbnail's aspect ratio and divide by 2 to center navigation on thumbnail
            bottom: calc((((124vw - 25px) / 2) * 0.56333) / 2);
            // Fix nav to be within bounds since slider is 124% of the screen
            left: 9.7%;
            width: 80.6%;
        }

        @include laptop {
            // Calculate single slide width compared to thumbnail's aspect ratio and divide by 2 to center navigation on thumbnail
            bottom: calc((((124vw - 50px) / 3) * 0.56333) / 2);
        }

        .swiper-button {
            &-prev, &-next {
                color: white;
            }
        }
    }
}