.modal {
    bottom: 0;
    display: none;
    left: 0;
    outline: 0;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1050;

    &__open {
        background-color: rgba(0, 0, 0, 0.75);
        display: block;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .modal-dialog {
        margin: 0 auto;
        position: relative;
        top: 25%;
        width: 50%;

        .modal-content {
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            background-color: #ffffff;
            border: 1px solid #999999;
            border: 1px solid rgba(0, 0, 0, .2);
            border-radius: 6px;
            -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
            outline: 0;
            padding: em(16);
            position: relative;

            .modal-body {
                padding-bottom: em(16);

                span.bold {
                    font-weight: bold
                }

                td, th {
                    word-break: break-all;
                }
            }
        }
    }

}

.fade.in {
    opacity: 1;
    transition: opacity .15s linear;
}

.modal__overlay {
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

.modal__container {
    background-color: #ffffff;
    border-radius: 14px;
    box-sizing: border-box;
    max-height: 100vh;
    max-width: 750px;
    overflow-y: auto;
    padding: 30px;
    width: 75%;
}

.modal__header {
    display: flex;
    justify-content: space-between;
}

.modal__title {
    font-size: 1.25rem;
    line-height: 1.25;
    margin-bottom: 0;
}

.modal__close {
    background: transparent;
    border: 0;
    cursor: pointer;
}

.modal__header .modal__close:before {
    content: "\2715";
}

.modal__content {
    line-height: 1.5;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.micromodal-slide {
    display: none;
}

.micromodal-slide.is-open {
    display: block;
}