/* -------------------
 * Mixins
\* ------------------- */
// TODO: See about phasing most of these out, some are duplicated entirely
@mixin for-phone-only {
    @media (max-width: 599px) {
        @content;
    }
}

@mixin for-tablet-portrait-up {
    @media (min-width: 600px) {
        @content;
    }
}

@mixin for-tablet-portrait-only {
    @media (min-width: 600px) and (max-width: 899px) {
        @content;
    }
}

@mixin for-tablet-landscape-up {
    @media (min-width: 900px) {
        @content;
    }
}

@mixin for-tablet-landscape-only {
    @media (min-width: 900px) and (max-width: 1149px) {
        @content;
    }
}

@mixin for-desktop-up {
    @media (min-width: 1150px) {
        @content;
    }
}

@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 600px) {
        @content;
    }
}

@mixin laptop {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1800px) {
        @content;
    }
}

@mixin media-query($width) {
    @media (min-width: #{$width}) {
        @content
    }
}
