.content-body {
    &__episode {
        align-items: center;
        color: black;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        &-content {
            padding: 0 15px;

            &--video-info {
                margin: 30px auto 0;

                @include laptop {
                    margin-top: 0;
                }
            }
        }

        &-video {
            background-color: black;
            width: 100%;

            @include laptop {
                margin-bottom: 40px;
            }

            &-title {
                @include font-montserrat("Bold");
                font-size: em(23px);
                margin-bottom: 25px;
                text-transform: uppercase;

                @include laptop {
                    font-size: em(30px);
                    line-height: em(35px, 30px);
                    margin-bottom: 15px;
                }
            }

            &-description {
                max-width: 1024px;

                @include tablet {
                    font-size: em(18px);
                    margin-bottom: 30px;
                }
            }
        }

        .featured-content {
            margin: 0 auto;
            max-width: 1024px;

            &__title {
                font-size: 24px;
                margin-bottom: 0;
                text-align: center;
            }

            &__container {
                column-gap: 30px;
                display: grid;
                grid-template-columns: 1fr;
                text-align: center;

                @include laptop {
                    grid-template-columns: repeat(3, 1fr);
                }

                * {
                    margin: revert;
                }

                &-content {
                    display: grid;
                    grid-template-rows: minmax(20px, 75px) auto auto;
                    margin: 0 auto;
                    max-width: 540px;

                    &-title {
                        font-size: 20px;
                        margin-bottom: 15px;
                        min-height: 7.5%;
                        text-transform: uppercase;
                    }

                    &-image {
                        margin: 0 auto;
                        width: 100%;
                    }

                    &-body {
                        display: grid;
                        gap: 30px;
                        grid-template-rows: 1fr 50px;
                        margin: 20px 0 40px;

                        &-description {
                            &-text {
                                margin-bottom: 0;
                            }

                            a.featured-content__container-content-body-description-website {
                                color: black;
                                text-decoration: underline;
                            }

                        }

                        a.featured-content__container-content-body-button {
                            @include font-montserrat("Bold");
                            border: 2px solid black;
                            color: black;
                            font-size: em(20px);
                            margin: 0 auto;
                            max-width: 475px;
                            padding: 15px 0;
                            text-transform: uppercase;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .brightcove-player {
            margin: 0 auto;
            max-width: 1000px;
        }
    }

    &__additional-nominees {
        color: black;
        margin: 0 auto 30px;
        max-width: 1024px;
        text-align: center;
        width: 100%;

        h3, h4 {
            text-transform: uppercase;
        }

        h3 {
            font-size: 24px;
            margin-bottom: 5px;
        }

        h4 {
            font-size: 18px;
        }

        h5 {
            font-size: 15px;
            font-weight: 400;
        }

        &-body {
            margin-bottom: 0;

            &-group {
                column-gap: 5%;
                display: flex;
                flex-flow: row wrap;
                margin-bottom: 40px;
                row-gap: 25px;

                .nominee {
                    flex-basis: 100%;

                    @include tablet {
                        flex-basis: 45%;
                    }

                    @include laptop {
                        flex-basis: 30%;
                    }
                }
            }
        }
    }

    &__winner {
        text-align: center;

        h3 {
            font-size: 24px;
            margin-bottom: 5px;
            text-transform: uppercase;
        }

        &-body {
            margin-bottom: 40px;

            h5 {
                font-size: 15px;
                font-weight: 400;
            }
        }
    }

    &__other-videos {
        column-gap: 20px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin: 0 auto;
        max-width: 900px;

        &-title {
            font-size: 24px;
            margin-bottom: 15px;
            text-align: center;
            text-transform: uppercase;
        }

        &-content {
            align-items: center;
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;
            text-align: center;

            @include media-query(768px) {
                flex-basis: 30%;
            }

            &-image {
                width: 285px;
            }

            &-number {
                @include font-montserrat("Black");
                bottom: -20px;
                color: white;
                font-size: 80px;
                left: 15px;
                paint-order: stroke fill;
                position: absolute;
                -webkit-text-stroke: 3px black;
            }

            &-title {
                font-size: 18px;
                text-transform: uppercase;
            }

            a {
                margin-bottom: 5px;
                overflow: hidden;
                position: relative;
            }
        }
    }
}

.divider {
    border-top: 2px solid #231f20;
    margin: 25px auto;
}