.featured-content {
    width: 100%;

    h3 {
        margin-bottom: 10px;
        text-align: center;
    }

    .admin__button--right {
        margin-bottom: 10px;
    }

    .text-column {
        width: 30%;
    }

    .textarea-column {
        width: 50%;
    }

    .action-column {
        width: 10%;
    }
}