.contact {
    .header-bar {
        padding-top: 50px;

        @include tablet {
            padding-top: 100px;
        }

        &__image {
            margin-bottom: 20px;
            max-width: 700px;
            width: 90%;

            @include tablet {
                margin-bottom: 35px;
            }
        }
    }

    .content {
        padding-top: 0;

        h2 {
            font-size: em(30px);
        }

        .center {
            text-align: center;
        }

        .form {
            gap: 10px;

            @include tablet {
                gap: 15px;
            }

            &__group {
                max-width: 350px;
                width: 100%;
            }

            &__input, &__select, &__textarea {
                margin: 0 auto;
            }

            &__button {
                margin-top: 5px;
                width: 80%;

                @include tablet {
                    margin-top: 10px;
                }
            }
        }
    }
}

