.pagination {
    align-items: center;
    display: flex;
    gap: 10px;
    margin-top: 10px;
    white-space: nowrap;

    @include tablet {
        margin-top: auto;
    }

    &__select {
        min-width: 70px;
    }
}